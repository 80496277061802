import React from 'react'

const IconRoundedCheckboxChecked = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" fill="#5DADFF" />
      <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" stroke="#5DADFF" />
      <path d="M11.3346 5.5L6.7513 10.0833L4.66797 8" stroke="white" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default React.memo(IconRoundedCheckboxChecked)

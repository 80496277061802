import { createContext, useEffect, useState } from "react";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {

    // Initialize state with default values
    const [userData, setUserData] = useState(() => {
        // Retrieve persisted data from storage when component mounts
        const storedData = localStorage.getItem('auth');
        return storedData ? JSON.parse(storedData) : { isLogged: false, otpRetries: [] };
    });

    const [formData, setFormData] = useState({
        name: '',
        birth_year: null,
        gender: null,
        phone_number: userData.phoneToValidate?.replace(' ', ''),
        isAccepted: false,
    })

    // Update state and persist data whenever it changes
    useEffect(() => {
        localStorage.setItem('auth', JSON.stringify(userData));
    }, [userData]);

    return (
        <AuthContext.Provider value={{ userData, setUserData, formData, setFormData }}>
            {children}
        </AuthContext.Provider>
    )
}
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ButtonBack from '../components/ButtonBack'
import Card from '../components/carousel/Card'
import { getPromoById } from '../services/promosService'
import { useAuthContext } from '../hooks/useAuthContext'
import copys, { termsAndPrivacy } from '../utils/copys'
import { promoStatus, routePaths, toastTypes } from '../utils/constants'
import CardQR from '../components/CardQR'
import { useActivateCoupon } from '../hooks/useActivateCoupon'
import { usePromoContext } from '../hooks/usePromoContext'
import ExpiredPromoCard from '../components/ExpiredPromoCard'
import DetailInfoCard from '../components/DetailInfoCard'
import CardPromoStatus from '../components/CardPromoStatus'
import ScreenLoading from './ScreenLoading'
import LockImg from '../assets/ilustraciones/lock.svg'
import TimeImg from '../assets/ilustraciones/time.svg'
import BrokenCouponImg from '../assets/ilustraciones/broken-coupon.svg'
import CouponCounter from '../components/CouponCounter'
import CountdownTimer from '../components/CountdownTimer'
import gtmService from '../services/gtmService';
import { checkIfIsExpired } from '../utils/dateUtils'
import { useToast } from '../hooks/useToast'
import * as Sentry from '@sentry/react'
import Modal from '../components/modals/Modal'
import ModalTerms from '../components/modals/ModalTerms'
import { acceptNewTerms, sendErrorReceivedConfirmation } from '../services/authService'

const aceptRejectActions = {
    ACCEPT: 'accept',
    REJECT: 'reject',
}

const ScreenPromoDetail = () => {

    const { userData, setUserData } = useAuthContext();
    const { setPromoData } = usePromoContext();
    const navigate = useNavigate()
    const { promoId } = useParams()
    const [promo, setPromo] = useState();
    const [showLoading, setShowLoading] = useState(true);
    const { activateCoupon } = useActivateCoupon();
    const [isTimerDone, setIsTimerDone] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [publicContentId, setPublicContentId] = useState('');
    const { showToast } = useToast();

    // Use a ref to access the current promo value in
    // an async callback.
    const promoRef = useRef(promo);
    promoRef.current = promo;

    const checkIfThereAreNewTerms = termsData => {
        if (termsData) {
            if (termsData.has_new_terms) {
                setShowModal(true);
                setPublicContentId(termsData.public_content_id);
            }
        }
    }

    const confirmErrorReceived = async (code) => {
        await sendErrorReceivedConfirmation({
            code,
            promotion_id: promoId
        })
    }

    const checkIsHasExchanged = () => {
        const promotion = promoRef.current;
        if (promotion?.status === promoStatus.ACTIVE && document.visibilityState === 'visible') {
            getPromoById(promoId).then(({ successful, data }) => {
                if (successful) {
                    const isPromoExpired = checkIfIsExpired(data.end_date);
                    if (isPromoExpired) {
                        setPromo({ status: promoStatus.EXPIRED })
                    } else {
                        if (!data.is_available) {
                            showToast(toastTypes.INFO, copys.toastCantRedeemInCurrentStore);
                            confirmErrorReceived(data.code).finally(() => {
                                goToHome();
                            });
                        }
                        if (data.status !== promotion.status) {
                            goToHome();
                        }
                    }
                    checkIfThereAreNewTerms(data.termsAndConditions);
                }
            })
        }
    }

    const activateCouponAndRefresh = async (promotion) => {
        const { successful, code } = await activateCoupon(promoId)
        if (successful) {
            setPromo({ ...promotion, status: promoStatus.ACTIVE, code })
            gtmService.pushEvent({
                event: "show_coupon",
                coupon: code, // Código del cupón que se canjea
                status: promotion.status // Estatus del cupon utilizado. Ej. 1,2,3,4
            })
        } else {
            setPromo({ status: promoStatus.EXPIRED })
        }
    }

    const goToLogin = () => {
        setPromoData({ promoId: promo.id })
        navigate('/' + routePaths.LOGIN)
    }

    const goToHome = () => navigate('/' + routePaths.HOME);

    const generateDetailCard = () => {
        let detailCard;
        if (userData.isLogged) {
            switch (promo?.status) {
                case promoStatus.ACTIVE:
                    detailCard = <CardQR code={promo?.code} />
                    break;
                case promoStatus.ONHOLD:
                    const title = isTimerDone ? 'Disponible, recarga la página' : <>{copys.buttonAvailable24h} <CountdownTimer targetDate={promo?.last_exchange_time} onComplete={() => setIsTimerDone(true)} /></>
                    detailCard = <CardPromoStatus labels={{ title, text: copys.detailCardOnHoldText }} buttonLabel={copys.buttonSeeMoreCoupons} onClick={goToHome}><img src={TimeImg} alt='coupon status' className='w-20' /></CardPromoStatus>
                    break;
                case promoStatus.DISABLED:
                    const labels = {
                        title: copys.detailCardDisabledTitle,
                        text: copys.detailCardDisabledText
                    };
                    detailCard = <CardPromoStatus labels={labels} onClick={goToHome} buttonLabel={copys.buttonSeeMoreCoupons} ><img src={BrokenCouponImg} alt='coupon status' className=' w-[136px]' /></CardPromoStatus>
                    break;
                default:
                    detailCard = '';
                    break;
            }
        } else {
            detailCard = <CardPromoStatus labels={{ title: copys.detailCardNotLogged }} buttonLabel={copys.headerLoginButton} onClick={goToLogin}><img src={LockImg} alt='coupon status' className='w-[60.2px]' /></CardPromoStatus>;
        }
        return detailCard;
    }

    const logout = () => {
        gtmService.pushClickButton(termsAndPrivacy.modal.buttonReject);
        setUserData({ isLogged: false, otpRetries: [] });
        navigate('/');
    }

    const acceptOrRejectTerms = async (action) => {
        const isAccepted = action === aceptRejectActions.ACCEPT ? true : false;
        const res = await acceptNewTerms({
            public_content_id: publicContentId,
            isAccepted,
        });
        if (!res.successful) {
            showToast(toastTypes.ERROR, copys.toastAcceptNewTermsFailed);
        }
        if (action === aceptRejectActions.ACCEPT) {
            setShowModal(false);
        } else {
            logout();
        }
    }

    useEffect(() => {
        const loadPromo = async (promoId) => {
            const { successful, data, errors, message } = await getPromoById(promoId);
            if (successful) {
                const isPromoExpired = checkIfIsExpired(data.end_date);
                if (isPromoExpired) {
                    gtmService.pushEvent({
                        event: "show_coupon",
                        coupon: promoId, // Código del cupón que se canjea
                        status: promoStatus.EXPIRED // Estatus del cupon utilizado. Ej. 1,2,3,4
                    })
                    setPromo({ status: promoStatus.EXPIRED })
                } else {
                    gtmService.pushEvent({
                        event: "show_coupon",
                        coupon: data.code, // Código del cupón que se canjea
                        status: data.status // Estatus del cupon utilizado. Ej. 1,2,3,4
                    })
                    checkIfThereAreNewTerms(data.termsAndConditions);
                    setPromo(data);
                    if (userData.isLogged && data.status === promoStatus.AVAILABLE) {
                        await activateCouponAndRefresh(data)
                    }
                }
            } else {
                console.log('Error al cargar detalle de cupon', errors)
                Sentry.captureException(errors, {
                    tags: {
                        flow: 'load_promotion_details',
                        promotion_id: promoId,
                        user_id: userData?.user?.id,
                    },
                });
                showToast(toastTypes.ERROR, message);
            }
            gtmService.pushPageView('/cliente-promo-visualizada', 'Cliente: Promo visualizada')
            setShowLoading(false)
            setPromoData({ promoId: 0 })
        };

        loadPromo(promoId);

        // Set interval every 15 seconds to check if coupon has been exchanged 
        const intervalId = setInterval(() => {
            checkIsHasExchanged()
        }, 7000);

        // Cleanup function to clear interval when component unmounts
        return () => clearInterval(intervalId);
    }, [])

    return (
        <div className='flex flex-col justify-start'>
            <ButtonBack customPage={'/home'} customLabel={copys.backButtonGoHome} />
            {showLoading && <ScreenLoading />}
            <Modal isOpen={showModal} isCentered>
                <ModalTerms onClickAccept={()=>acceptOrRejectTerms(aceptRejectActions.ACCEPT)} onClickReject={()=>acceptOrRejectTerms(aceptRejectActions.REJECT)} />
            </Modal>
            <div className="flex justify-center">
                <div className="flex flex-col items-center p-6 gap-4 min-[370px]:w-[370px]">
                    {
                        promo && promo?.status !== promoStatus.EXPIRED && (
                            <>
                                <Card promo={promo} status={promo.status} isImageOnly />
                                {
                                    generateDetailCard()
                                }
                                {userData.isLogged && <CouponCounter count={promo.exchanges_count} exchangesByPromoAllowed={promo.exchanges_allowed} />}
                                <DetailInfoCard html={promo.public_content?.content} />
                            </>
                        )
                    }
                    {
                        promo?.status === promoStatus.EXPIRED && (
                            <ExpiredPromoCard />
                        )
                    }

                </div>
            </div>


        </div>
    )
}

export default ScreenPromoDetail
